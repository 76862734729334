.container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 700px;
  margin: 0.75rem;
  flex-direction: column;
}

.errorHeader {
  display: flex;
  align-items: center;
  flex-direction: row;
}

.errorContent {
  margin: 0;
  line-height: 2px;
}

.alertStyle {
  color: #faa21b;
  margin-right: 10px;
  padding-right: 0.25rem;
}
