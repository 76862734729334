.container {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-top: auto;
  padding-top: 2rem;
  width: 100%;
  font-family: ArtifaktElement, sans-serif;
  font-size: var(--font-size-small, 0.8rem);
  font-weight: var(--font-weight-regular, 400);
}
.text {
  color: var(--color-gray-050, #666666);
}
