.containerOuter {
  align-items: stretch;
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
  justify-content: center;
  margin-top: 3.125rem; /* gives some space for an eventual header */
  min-height: calc(100vh - 4rem);
}

.containerInner {
  align-content: space-between;
  align-items: center;
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
  font-size: var(--font-size-small, 0.9rem);
  justify-content: center;
  height: 83vh;
}

.text {
  margin-top: 36px !important; /* SGDS… */
  margin-bottom: 0 !important; /* SGDS */
}

.button {
  width: 210px !important; /* SGDS… */
  height: 36px;
  margin-top: 36px !important; /* SGDS… */
  cursor: pointer !important; /* SGDS… */
}
