.contentTitle {
  color: var(--color-black, black) !important;
  font-size: var(--font-size-large, 1.2rem) !important;
  margin: 24px 0px 8px 8px !important;
}

.content {
  margin: 8px 0px 0px 8px !important;
}

.releaseList {
  width: 100%;
  margin: 0;
  padding: 0;
}

.headerStyle {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  flex-direction: row;
}

.release {
  width: 100%;
  border-top: 1px solid rgb(220, 220, 220);
  border-bottom: 1px solid rgb(220, 220, 220);
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  font-size: var(--font-size-medium, 1rem) !important;
  font-family: ArtifaktElement, sans-serif;
}

.releaseName {
  padding: 1rem 0;
  margin-left: 0.5rem;
}

.downloadContent {
  padding: 1rem 3rem;
  min-width: fit-content;
}
