.containerOuter {
  align-items: stretch;
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
  justify-content: center;
  margin-top: 3.125rem; /* gives some space for an eventual header */
}

.containerInner {
  align-content: space-between;
  align-items: center;
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
  justify-content: center;
  min-height: calc(100vh - 4rem);
}

.noSiteContainer {
  margin-top: 30px;
  font-family: ArtifaktElement, sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.noSiteTextContainer {
  margin-top: 30px;
  font-family: ArtifaktElement, sans-serif;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.noteFiller {
  flex-grow: 1;
  min-width: 20%;
}
.noteContainer {
  margin-top: 30px;
  font-family: ArtifaktElement, sans-serif;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;
}
.userEmail {
  line-height: 4;
  font-size: var(--font-size-x-small, 0.75rem);
  font-weight: var(--font-weight-regular, 400);
}
.errorNoSite {
  margin: 0;
  line-height: 4;
}
.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 5rem;
  padding: 0.5rem 0;
  margin: 3rem 0;
}

.buttonContainer {
  display: flex;
  flex-direction: column;
  button {
    margin-top: 1.13em !important;
    border-radius: 0.125rem;
    cursor: pointer !important;
  }
}

.userAvatar {
  font-size: 32px;
  padding-top: 10px;
}
