.container {
  display: flex;
  align-items: center;
  margin: 50px auto;
  margin-top: 3.125rem;
  width: 655px;
  flex-direction: column;
}

.contentTitle {
  margin-left: 0.5rem !important; /* SGDS… */
  color: var(--color-black, black) !important; /* SGDS */
}

.content {
  margin-left: 0.5rem !important; /* SGDS… */
}

.list {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
