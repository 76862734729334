.container {
  margin-top: 30px;
  width: 368px;
  font-family: ArtifaktElement, sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.cardSpace {
  margin: 1rem 0 0;
}
.cardSiteAddress {
  float: left;
  margin: 0 0 0 18px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: var(--color-black, black);
  font-size: var(--font-size-small, 0.875rem);
  font-weight: var(--font-weight-regular, 400);
}

.cardCaretRight {
  margin: 0 15px 0 15px;
  float: right;
  color: var(--color-black, black);
  display: flex;
}
.filler {
  flex-grow: 1;
}
.missingSitesNote {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;
  max-block-size: fit-content;
}

.helpStyle {
  svg {
    color: var(--color-green, #87bc40);
    min-width: 1rem;
    min-height: 1rem;
    margin-left: 1rem;
    margin-right: 1rem;
  }
  padding-right: 0.25rem;
}

.contentNote {
  font-size: var(--font-size-x-small, 0.9rem) !important; /* SGDS… */
  align-items: center;
  margin: 0.5rem 0.5rem 1rem 0 !important; /* SGDS… */
}

.cardStyle {
  width: 368px;
  height: 52px;
  margin-bottom: 0.875rem;
  flex-direction: row !important; /* SGDS… */
  align-items: center;
  justify-content: left;
  overflow: hidden;
  user-select: none;
}

.cardLinkStyle {
  color: var(--color-black, black);
}
