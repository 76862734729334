.container {
  width: 368px;
  margin-top: 30px;
  margin-bottom: 16px;
  font-family: ArtifaktElement, sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.contentTitle {
  margin-bottom: 16px;
}
.cardContent {
  font-size: var(--font-size-medium, 1rem);
  margin: 0 0 0 18px;
  float: left;
}
.cardCaretRight {
  margin: 0 15px 0 32px;
  float: right;
  color: var(--color-black, black);
  display: flex;
}
.filler {
  flex-grow: 1;
}

.card {
  display: flex;
  width: 368px;
  height: 52px;
  flex-direction: row !important; /* SGDS… */
  align-items: center;
  justify-content: left;
  overflow: hidden;
  user-select: none;
}

.cardLinkStyle {
  color: var(--color-black, black) !important; /* SGDS… */
}
